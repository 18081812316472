*{
  margin:0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h1, h2{
  font-family: "Poppins", sans-serif !important;
}
.app-container {
    display: flex;
    flex-grow: 1;
    max-width: 190rem;
  }
  
  .content {
    flex-grow: 1;
    text-align: center;
    padding: 2rem 9rem;
    margin-left: 13rem;
    color: var(--CONTENT-COLOR);
    overflow-y: auto;
  }
  
  .content h2 {
    color: rgb(33, 35, 42);
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }
  
  .content p {
    font-family: 'Open Sans', sans-serif;
    line-height: 1.8;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 1235px) {
    .content {
      padding: 1rem;
      margin-left: 0;
      margin-top: 7rem;
    }
  }
  
  @media (max-width: 768px) {
    .content {
      margin-top: 7rem;
    }
  }