@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --NAV-COLOR: rgb(212, 212, 212);
  --NAV-BORDER-COLOR: #525151;
  /* --NAV-BGCOLOR: rgb(51, 55, 62); */
  --NAV-BGCOLOR: rgb(34, 36, 41);
  --NAV-BORDER: 2px solid var(--NAV-BORDER-COLOR);
  --CONTENT-H2-COLOR: rgb(212, 212, 212);
  --CONTENT-COLOR: rgb(188, 197, 206);
  --LINK-COLOR: white;
  --LINK-HOVER-BGCOLOR: rgba(62, 216, 219, 0.866);
  --LINK-FOCUS-BGCOLOR: rgba(62, 216, 219, 0.866);
  --LINK-ACTIVE-COLOR: rgb(82, 81, 81);
  --cont-color: rgb(18, 17, 17); /* Dark background for containers */
  --ac-color: rgba(62, 216, 219, 0.866); /* Accent color for labels and headings */
  --text-color: #ffffff; /* White text color */
  --input-focus-color: rgb(77, 77, 77);
}
*{
  margin:0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body {
  background-color: black;
}