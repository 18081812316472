body.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: black;
  color: white;
  font-size: 1rem; /* Base font size */
}

.login-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
}

.login-container {
  display: flex;
  flex-direction: row;
  background-color: rgb(18, 17, 17);
  border-radius: 0.5rem; /* 8px => 0.5rem */
  overflow: hidden;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); /* 4px, 8px => 0.25rem, 0.5rem */
  width: 100%;
  max-width: 56.25rem; /* 900px => 56.25rem */
  height: 60%;
  z-index: 1;
}

.logo-section {
  flex: 1;
  padding-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccs-logo {
  max-width: 90%;
  height: auto;
}
.logo-section p {
  color: white;
}
.form-section {
  flex: 1;
  padding: 2.5rem; /* 40px => 2.5rem */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-section h2 {
  text-align: center;
  margin-bottom: 1.25rem; /* 20px => 1.25rem */
  color: white;
}

.form-button {
  width: 70%;
  padding: 0.625rem; /* 10px => 0.625rem */
  margin-top: 0.625rem; /* 10px => 0.625rem */
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 0.5rem; /* 8px => 0.5rem */
  cursor: pointer;
}

.form-button:hover {
  background-color: #357ae8;
}

.ccs-login {
  background-color: #34a853;
}

.ccs-login:hover {
  background-color: #2b8e42;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) { 
  .login-container {
    flex-direction: column;
    height: auto;
    margin-top: 1.25rem; /* 20px => 1.25rem */
    margin: 1.25rem; /* 20px => 1.25rem */
    width: 90%; /* Reduce container width */
  }

  .logo-section {
    padding: 1.25rem; /* 20px => 1.25rem */
  }

  .ccs-logo {
    max-width: 60%;
  }

  .form-section {
    padding: 1.25rem; /* 20px => 1.25rem */
  }

  .form-button {
    width: 90%;
    padding: 0.5rem; /* 8px => 0.5rem */
    margin-top: 0.5rem; /* 8px => 0.5rem */
    font-size: 0.875rem; /* Smaller font size */
  }

  .form-section h2 {
    font-size: 1.125rem; /* 18px => 1.125rem */
  }
}

@media (max-width: 480px) { 
  .login-outer-container {
    min-height: 100vh;
    display: flex;
    align-items: flex-start; /* Align to the top */
    justify-content: center;
    padding-top: 1vh; /* 1% of viewport height */
  }

  .login-container {
    flex-direction: column;
    height: auto;
    width: 95%;
    max-width: 100%; /* Ensure it doesn't exceed screen width */
    margin: 0 auto; /* Center horizontally */
    padding: 0.5rem;
  }

  .ccs-logo {
    max-width: 50%;
  }

  .form-section {
    padding: 0.625rem; /* 10px => 0.625rem */
  }

  .form-button {
    width: 100%;
    padding: 0.375rem; /* 6px => 0.375rem */
    margin-top: 0.375rem; /* 6px => 0.375rem */
    font-size: 0.75rem; /* 12px => 0.75rem */
  }

  .form-section h2 {
    font-size: 1rem; /* 16px => 1rem */
  }
}
@media (max-width: 320px) {
  .login-outer-container {
    padding-top: 0.5vh; /* Even smaller top padding for very small screens */
  }

  .login-container {
    padding: 0.25rem;
  }
}